import React, { useContext } from "react";
import { Context as AuthContext } from "context/Auth";
import { Switch, Route } from "react-router";
import ProtectedRoute from "components/Secured/ProtectedRoute";
import { IntlContext } from "context";

const SimulationPer = React.lazy(() => import("app/SimulationPer"));

const AuthModule = () => {
    const auth = useContext(AuthContext);
    const intl = useContext(IntlContext);

    document.title = intl.formatMessage('app.title');

    return (
        <Switch>
            <ProtectedRoute path='/simulation/per/' capability='simulation.per.list' render={() => <SimulationPer />} />
            <ProtectedRoute path='/' capability='simulation.per.list' render={() => <SimulationPer />} />
        </Switch>
    );
};

const ModuleRouter = () => {
    return <AuthModule />;
};

export default ModuleRouter;
