import { useReducer, useEffect, useState } from "react";
import Axios from "axios";

function dataFetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_INIT":
            return {
                ...state,
                isLoading: true,
                hasError: false,
                error: null,
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                data: action.payload,
                error: null,
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true,
                error: action.payload,
            };
        case "REFRESH_DATA":
            return {
                ...state,
                refresh: !state.refresh,
            };
        default:
            throw new Error();
    }
}

export default function usePromise(promise, defaultPromiseParams, initialData) {
    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        hasError: false,
        error: null,
        data: initialData,
        refresh: false,
    });

    const [promiseParams, changePromiseParams] = useState(defaultPromiseParams);

    useEffect(() => {
        let didCancel = false;
        const fetchData = async () => {
            dispatch({ type: 'FETCH_INIT' });
            try {
                let result = await promise(promiseParams);
                if (!didCancel) {
                    dispatch({ type: 'FETCH_SUCCESS', payload: result });
                }
            } catch (error) {
                if (!didCancel && !Axios.isCancel(error)) {
                    dispatch({ type: 'FETCH_FAILURE', payload: error });
                }
            }
        };
        fetchData();
        return () => {
            didCancel = true;
        };
    }, [promise, state.refresh, promiseParams]);

    return {
        isLoading: state.isLoading,
        hasError: state.hasError,
        error: state.error,
        data: state.data,
        refreshData: () => dispatch({ type: 'REFRESH_DATA' }),
        promiseParams,
        changePromiseParams,
    };
}
