import React from "react";
import { Route } from "react-router-dom";
import ProtectedComponent from "./ProtectedComponent";

const ProtectedRoute = ({ capability, ...props }) => {
    return (
        <ProtectedComponent capability={capability}>
            <Route {...props} />
        </ProtectedComponent>
    );
};

export default ProtectedRoute;
