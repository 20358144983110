import React from "react";
import AuthenticationService from "dataProvider/AuthenticationService";
import getIn from "helpers/getIn";
import Acl from "./Acl";

function storeDataToAccount(data) {
    const account = {
        user: getIn(data, "user"),
        acl: new Acl(),
    };

    data.capabilities.forEach((capability) => {
        account.acl.allow(capability);
    });
    return account;
}

const AuthContext = React.createContext({});

class AuthProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            account: null,
            isAllowed: this.isAllowed,
            filterAllowed: this.filterAllowed,
        };
    }

    componentDidMount = async () => {
        const data = await AuthenticationService.fresh();
        const account = Object.assign({}, data);
        this.setState({ isLoggedIn: true, account: storeDataToAccount(account) });
    };

    isAllowed = (capability) => {
        return !!this.state.account && this.state.account.acl.isAllowed(capability);
    };

    filterAllowed = (actions, acl) => {
        const actionsAllowed = {};
        for (const action in actions) {
            if (!acl[action]) {
                actionsAllowed[action] = actions[action];
            } else if (this.isAllowed(acl[action])) {
                actionsAllowed[action] = actions[action];
            }
        }
        return actionsAllowed;
    };

    render() {
        return <AuthContext.Provider value={this.state}>{this.props.children}</AuthContext.Provider>;
    }
}

const Consumer = AuthContext.Consumer;

export { AuthProvider as Provider, Consumer, AuthContext as Context };
