import React, { useState } from "react";
import { Link } from "react-router-dom";

const BreadcrumbContext = React.createContext({
    addItem: () => {},
    removeItem: () => {},
    renderMenu: () => {},
    cleanItems: () => {},
    updateItems: () => {},

});

const BreadcrumbProvider = ({children}) => {
    const [items, setItems] = useState([]);

    const renderMenu = () => {
        const lastItemActive = items.filter((item) => item.disabled !== true).length - 1;
        return (
            <ul className="topbar-breadcrumb">
                {items.map((item, index) => {
                    let itemClass = "topbar-breadcrumb__item";

                    if (item.disabled) {
                        itemClass += " disabled";
                        return (
                            <li className={itemClass} key={item.id}>
                                <span>{item.label}</span>
                            </li>
                        );
                    }

                    if (index ===  lastItemActive) {
                        if (items.length > 1) {
                            itemClass += " active";
                        }
                        return (
                            <li className={itemClass} key={item.id}>
                                <span>{item.label}</span>
                            </li>
                        );
                    }

                    if (item.target) {
                        return (
                            <li className={itemClass} key={item.id}>
                                <Link to={item.target} className="topbar-breadcrumb__link">
                                    {item.label}
                                </Link>
                            </li>
                        );
                    }

                    if (item.onClick) {
                        return (
                            <li className={itemClass} key={item.id}>
                                <span className="topbar-breadcrumb__link" onClick={item.onClick} >{item.label}</span>
                            </li>
                        )
                    }

                    return null;
                })}
            </ul>
        );
    };

    const addItem = (newItem) => {
        const itemExists = items.filter((item) => item.id === newItem.id).length;

        if (itemExists) {
            return;
        }
        setItems([...items, newItem]);
    };

    const removeItem = (id, itemId) => {
        setItems([...items.filter((item) => item.id !== itemId)]);
    };

    const updateItems = (newItems) => {
        if (items.length !== newItems.length) {
            setItems(newItems);
            return;
        }

        if (newItems.filter((item, index) => {
            return Object.entries(item).toString() !== Object.entries(items[index]).toString();
        }).length > 0) {
            setItems(newItems);
            return;
        }
    };

    const cleanItems = () => {
        if (items.length > 0) {
            setItems([]);
        }
    };

    return (
        <BreadcrumbContext.Provider value={{
            addItem,
            removeItem,
            renderMenu,
            cleanItems,
            updateItems,
        }}>
            {children}
        </BreadcrumbContext.Provider>
    );
};

const Consumer = BreadcrumbContext.Consumer;

export { Consumer, BreadcrumbContext as Context, BreadcrumbProvider as Provider };