import React from "react";

class ErrorBoundary extends React.Component {
    state = {
        hasError: false,
    };

    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo,
        });
    }

    render() {
        if (this.state.hasError) {
            return (
                <React.Fragment>
                    <h2>Une erreur est survenue</h2>
                </React.Fragment>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
