import React, { useState, useCallback } from "react";
import usePromise from "helpers/usePromise";
import staticCache from "helpers/cache/Static";

const ListContext = React.createContext({
    isLoading: false,
    hasError: false,
    data: Object.assign([], {
        pager: { total: 0, total_page: 1, last_page: 1, per_page: 50, current_page: 1 },
        active_filters: {},
    }),
    refreshData: () => {},
    setLoading: () => {},
    changeFetcherParams: () => {},
    selectedIds: [],
    setSelectedIds: () => {},
    selectedId: undefined,
    setSelectedId: () => {},
});

const getCache = (cacheKey, propertyName, defaultValue) => {
    if (cacheKey && staticCache.has(cacheKey)) {
        const cache = staticCache.get(cacheKey);
        return cache[propertyName] || defaultValue;
    }
    return defaultValue;
};

const setCache = (cacheKey, propertyName, value) => {
    if (cacheKey) {
        let merge = {};
        if (staticCache.has(cacheKey)) {
            merge = staticCache.get(cacheKey);
        }
        staticCache.set(cacheKey, Object.assign(merge, { [propertyName]: value }));
    }
};

export function ListProvider({dataFetcher, cacheKey, children, defaultFetcherParams,}) {
    const { isLoading, hasError, error, data, refreshData, changePromiseParams } = usePromise(
        dataFetcher,
        getCache(cacheKey, "fetcherParams", defaultFetcherParams),
        Object.assign([], {
            pager: { total: 0, total_page: 1, last_page: 1, per_page: 50, current_page: 1 },
            active_filters: {},
        })
    );

    const [isLoadingState, setLoading] = useState(false);
    const [selectedIds, setInternalSelectedIds] = useState(getCache(cacheKey, "selectedIds", []));
    const [selectedId, setSelectedId] = useState(undefined);

    const setSelectedIds = useCallback(
        (selectedIds) => {
            setCache(cacheKey, "selectedIds", selectedIds);
            setInternalSelectedIds(selectedIds);
        },
        [setInternalSelectedIds, cacheKey]
    );

    const changeFetcherParams = useCallback(
        (params) => {
            const mergeParams = Object.assign({}, params);
            setSelectedIds([]);
            setCache(cacheKey, "fetcherParams", mergeParams);
            changePromiseParams(mergeParams);
        },
        [changePromiseParams, setSelectedIds, cacheKey]
    );

    return (
        <ListContext.Provider
            value={{
                isLoading: isLoadingState || isLoading,
                hasError,
                error,
                data,
                refreshData,
                setLoading,
                changeFetcherParams,
                selectedIds,
                setSelectedIds,
                selectedId,
                setSelectedId,
            }}
        >
            {children}
        </ListContext.Provider>
    );
}

const Consumer = ListContext.Consumer;

export { Consumer, ListProvider as Provider, ListContext as Context };
