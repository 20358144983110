import React from "react";

const FlashActionsContext = React.createContext({
    addSuccess: () => {},
    addError: () => {},
    addWarning: () => {},
    addInfo: () => {},
    removeFlashMessage: () => {},
});

const Consumer = FlashActionsContext.Consumer;

let nextFlashMessageId = 1;

class Provider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            actions: {
                addSuccess: this.addSuccess,
                addError: this.addError,
                addWarning: this.addWarning,
                addInfo: this.addInfo,
                removeFlashMessage: this.removeFlashMessage,
            },
        };
    }

    removeFlashMessage = (flashMessageId) => {
        this.setState({
            messages: this.state.messages.filter((f) => flashMessageId !== f.id),
        });
    };

    addSuccess = (text, duration = 10000) => {
        this.addFlashMessageOfType("success", duration, text);
    };

    addError = (text, duration = 10000) => {
        this.addFlashMessageOfType("error", duration, text);
    };

    addWarning = (text, duration = 10000) => {
        this.addFlashMessageOfType("warning", duration, text);
    };

    addInfo = (text, duration = 10000) => {
        this.addFlashMessageOfType("info", duration, text);
    };

    addFlashMessageOfType = (type, duration, text) => {
        const id = nextFlashMessageId++;
        const flashMessage = { id, type, duration, text };
        this.setState({
            messages: [...this.state.messages, flashMessage],
        });
    };

    render() {
        const { FlashComponent } = this.props;
        return (
            <React.Fragment>
                <FlashComponent flash={this.state} />
                <FlashActionsContext.Provider value={this.state.actions}>
                    {this.props.children}
                </FlashActionsContext.Provider>
            </React.Fragment>
        );
    }
}

export { Provider, Consumer, FlashActionsContext as Context };
