import React from "react";
import { IntlProvider, injectIntl } from "react-intl";
import LanguageService from "dataProvider/Language";
import { Backdrop, Loader } from "ui/Loader";

const IntlContext = React.createContext({});

class BaseProvider extends React.Component {
    formatMessage = (id, values = {}) => {
        let index = 0;
        return this.props.intl.formatMessage(
            {id},
            {
                ...values,
                bold: chunks => (
                    <strong key={++index}>{chunks}</strong>
                ),
                bleu_lumiere: chunks => (
                    <span key={++index} className="text-color--bleu_lumiere">
                        {chunks}
                    </span>
                ),
                vert_eres: chunks => (
                    <span key={++index} className="text-color--vert_eres">
                        {chunks}
                    </span>
                ),
                small: chunks => (
                    <span key={++index} className="font__weight--300">
                        {chunks}
                    </span>
                ),
                br: <br key={++index} />
            }
        )
    };

    formatCurrency = (value, currency = 'EUR', round = true) => {
        return this.props.intl.formatNumber(value, {
            style: "currency",
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: round ? 0 : 2,
        });
    };

    formatPercent = (value, round = true) => {
        return this.props.intl.formatNumber(value, {
            style: "percent",
            maximumFractionDigits: round ? 0 : 2,
        });
    }

    formatHTMLMessage = (id) => {
        if (this.props.intl.messages[id]) {
            return <span dangerouslySetInnerHTML={{ __html: this.props.intl.messages[id]}}></span>
        }

        return <span dangerouslySetInnerHTML={{ __html: id}}></span>
    }

    render() {
        return (
            <IntlContext.Provider value={{
                ...this.props.intl,
                formatMessage: this.formatMessage,
                formatCurrency: this.formatCurrency,
                formatPercent: this.formatPercent,
                formatHTMLMessage: this.formatHTMLMessage,
            }}>
                {this.props.children}
            </IntlContext.Provider>
        );
    }
}

const SubProvider = injectIntl(BaseProvider);

class Provider extends React.Component {
    state = {
        translations: {},
        localeCode: "fr",
        status: "LOADING",
    };

    componentDidMount = () => {
        this.loadTranslations(this.state.localeCode);
    };

    loadTranslations = (localeCode) => {
        this.setState({
            status: "LOADING",
        });
        return LanguageService.list(localeCode).then((translations) => {
            this.setState({
                translations,
                status: "IDLE",
                localeCode,
            });
        });
    };

    render() {
        if (this.state.status === "LOADING") {
            const loadingMessages = {
                fr: "Chargement...",
            };
            return (
                <Backdrop>
                    <Loader message={loadingMessages[this.state.localeCode]} />
                </Backdrop>
            );
        }
        return (
            <IntlProvider locale={this.state.localeCode} key={this.state.localeCode} messages={this.state.translations}>
                <SubProvider>{this.props.children}</SubProvider>
            </IntlProvider>
        );
    }
}

const Consumer = IntlContext.Consumer;

export { Consumer, Provider, IntlContext as Context };
