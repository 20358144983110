class Node {
    name;

    allowed;

    children;

    constructor(name, allowed = false) {
        this.name = name;
        this.allowed = allowed;
        this.children = {};
    }

    addChild(node) {
        this.children[node.name] = node;
    }

    allow(aclTree) {
        const tree = aclTree.slice();

        if (tree.length > 0) {
            const child = tree.shift();
            if (!child) {
                return;
            }
            if (!this.children[child]) {
                this.addChild(new Node(child));
            }
            this.children[child].allow(tree);
        } else {
            this.allowed = true;
        }
    }

    isAllowed(aclTree) {
        const tree = aclTree.slice();

        if (tree.length > 0) {
            const child = tree.shift();

            if (child === "*") {
                for (const name in this.children) {
                    return this.children[name].isAllowed(tree);
                }
            } else {
                if (child && this.children[child]) {
                    return this.children[child].isAllowed(tree);
                } else if (Object.keys(this.children).length === 0) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return this.allowed;
    }
}

export default Node;
