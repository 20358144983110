import React, { useContext } from "react";
import { Context as AuthContext } from "context/Auth";

const ProtectedComponent = ({ capability, children }) => {
    const { isAllowed } = useContext(AuthContext);

    if (capability ? isAllowed(capability) : true) {
        return <React.Fragment>{children}</React.Fragment>;
    }
    return null;
};

export default ProtectedComponent;
