import React, { Suspense } from "react";
import { Provider as AuthProvider } from "context/Auth";
import { Provider as IntlProvider } from "context/Intl";
import { Provider as FlashMessagesProvider } from "context/FlashMessages";
import { Provider as BreadcrumbProvider } from "context/Breadcrumb";
import { BrowserRouter } from "react-router-dom";

import "assets/scss/main.scss";

import FlashMessages from "components/App/FlashMessages";
import ErrorBoundary from "components/App/ErrorBoundary";
import Loader from "components/App/Loader";
import ModuleRouter from "./ModuleRouter";
import axios from "axios";

axios.interceptors.response.use(undefined, error => {
    const { status, data, config } = error.response;
    if (status === 401) {
        window.location = process.env.REACT_APP_SERVER_URL + "authentication/keycloak/redirect";
    }
    return Promise.reject(error);
});

const App = () => {
    let head = document.head || document.getElementsByTagName('head')[0],
        style = document.createElement('link'),
        favicon = document.createElement('link');

    style.rel = 'stylesheet';
    style.type = 'text/css';
    style.href = process.env.REACT_APP_SERVER_URL + 'style';

    head.appendChild(style);

    favicon.rel = 'icon';
    favicon.href = process.env.REACT_APP_SERVER_URL + 'favicon';

    head.appendChild(favicon);

    return (
        <ErrorBoundary>
            <AuthProvider>
                <IntlProvider>
                    <FlashMessagesProvider FlashComponent={FlashMessages}>
                        <BrowserRouter basename={process.env.PUBLIC_URL}>
                            <Suspense fallback={<Loader />}>
                                <BreadcrumbProvider>
                                    <ModuleRouter />
                                </BreadcrumbProvider>
                            </Suspense>
                        </BrowserRouter>
                    </FlashMessagesProvider>
                </IntlProvider>
            </AuthProvider>
        </ErrorBoundary>
    );
};

export default App;
