import React, { useEffect, useCallback, useRef } from "react";

const FlashMessage = ({ content, type, duration, animationDuration, onFadeOut }) => {
    const timeOutId = useRef(null);

    const fadeOut = useCallback(() => {
        timeOutId && clearTimeout(timeOutId);
        timeOutId.current = setTimeout(() => onFadeOut(), animationDuration);
    }, [onFadeOut, animationDuration]);

    useEffect(() => {
        timeOutId.current = null;
        if (duration) {
            timeOutId.current = setTimeout(fadeOut, duration);
        }
        return () => {
            clearTimeout(timeOutId.current);
        };
    }, [duration, fadeOut]);

    let iconClass = null;

    switch (type) {
        case "error":
            iconClass = "tio-block_ads";
            break;
        case "warning":
            iconClass = "tio-error";
            break;
        case "success":
            iconClass = "tio-checkmark_circle";
            break;
        case "info":
            iconClass = "tio-info";
            break;
        default:
            iconClass = null;
    }

    return (
        <div className={`flash-message flash-message--${type}`}>
            {iconClass && (
                <div className="flash-message__icon">
                    <i className={iconClass}></i>
                </div>
            )}
            <div className="flash-message__content">{content}</div>
            <button className="flash-message__remove-btn" onClick={fadeOut}></button>
        </div>
    );
};

FlashMessage.timeOutId = null;

export default FlashMessage;
