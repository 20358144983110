const toPath = (key) => {
    if (key === null || key === undefined || !key.length) {
        return [];
    }
    if (typeof key !== "string") {
        throw new Error("toPath() expects a string");
    }
    return key.split(/[.[\]]+/).filter(Boolean);
};

const getIn = (state, complexKey) => {
    const path = toPath(complexKey);
    let current = state;
    for (let i = 0; i < path.length; i++) {
        const key = path[i];
        if (
            current === undefined ||
            current === null ||
            typeof current !== "object" ||
            (Array.isArray(current) && Number.isNaN(Number(key)))
        ) {
            return undefined;
        }
        current = current[key];
    }
    return current;
};

export default getIn;
