import axios from "axios";

const API_URL = process.env.REACT_APP_SERVER_URL;
const SESSION_TOKEN_KEY = process.env.REACT_APP_SESSION_HEADER_NAME;
const SESSION_EXPIRATION_DATE_KEY = `${SESSION_TOKEN_KEY}_expiration_date`;


function getSessionExpirationDate() {
    const data = localStorage.getItem(SESSION_EXPIRATION_DATE_KEY);
    if (data) {
        return new Date(data);
    }
    return null;
}

function sessionIsExpired() {
    const expirationDate = getSessionExpirationDate();
    if (expirationDate) {
        return expirationDate < new Date();
    }
    return true;
}

class fetch {
    sessionIsExpired() {
        return sessionIsExpired();
    }

    get(url, config = {}) {
        const CancelToken = axios.CancelToken.source();
        let query = axios.get(
            API_URL + url,
            Object.assign({}, config, { cancelToken: CancelToken.token, withCredentials: true})
        );
        query.cancel = CancelToken.cancel;
        return query;
    }

    delete(url, config = {}) {
        const CancelToken = axios.CancelToken.source();
        let query = axios.delete(
            API_URL + url,
            Object.assign({}, config, { cancelToken: CancelToken.token, withCredentials: true})
        );
        query.cancel = CancelToken.cancel;
        return query;
    }

    post(url, data, config = {}) {
        const CancelToken = axios.CancelToken.source();
        let query = axios.post(
            API_URL + url,
            data,
            Object.assign({}, config, { cancelToken: CancelToken.toke, withCredentials: true})
        );
        query.cancel = CancelToken.cancel;
        return query;
    }

    put(url, data, config = {}) {
        const CancelToken = axios.CancelToken.source();
        let query = axios.put(
            API_URL + url,
            data,
            Object.assign({}, config, { cancelToken: CancelToken.token, withCredentials: true})
        );
        query.cancel = CancelToken.cancel;
        return query;
    }

    patch(url, data, config = {}) {
        const CancelToken = axios.CancelToken.source();
        let query = axios.patch(
            API_URL + url,
            data,
            Object.assign({}, config, { cancelToken: CancelToken.token, withCredentials: true})
        );
        query.cancel = CancelToken.cancel;
        return query;
    }
}

const query_debug = function (response) {
    if (process.env.NODE_ENV === "development") {
        if (response.data && response.data.debug) {
            const debug = response.data.debug;
            const config = response.config;
            if (debug.warnings && debug.warnings.length) {
                console.groupCollapsed(
                    `%c Request warning : ${config.method} ${config.url}`,
                    "color: #856404; background-color: #fff3cd; border-color: #ffeeba;"
                );
                console.log(debug.warnings);
                console.groupEnd();
            }
            if (debug.custom_data) {
                console.groupCollapsed(
                    `%c Request custom data : ${config.method} ${config.url}`,
                    "color: #0c5460; background-color: #d1ecf1; border-color: #bee5eb;"
                );
                for (let index = 0; index < debug.custom_data.length; index++) {
                    const element = debug.custom_data[index];
                    console.log(element);
                }
                console.groupEnd();
            }
        }
    }
};

// Axios Session interceptor
axios.interceptors.response.use(
    function (response) {
        if (response.headers[SESSION_EXPIRATION_DATE_KEY]) {
            localStorage.setItem(SESSION_EXPIRATION_DATE_KEY, response.headers[SESSION_EXPIRATION_DATE_KEY]);
        }
        query_debug(response);
        return response;
    },
    function (error) {
        if (error.response) {
            query_debug(error.response);
        }
        // Do something with response error
        return Promise.reject(error);
    }
);

const singleton = new fetch();
export default singleton;

export function parseResponse(fetcher) {
    return fetcher.then((response) => {
        return response.data.data;
    });
}

export function parseListResponse(fetcher) {
    return fetcher.then((response) => {
        const payload = response.data;
        return Object.assign([...payload.data], {
            active_filters: payload.filters,
            pager: payload.pager,
        });
    });
}
