import React from "react";
import { Backdrop, Loader as LoaderUI } from "ui/Loader";

const Loader = () => {
    return (
        <Backdrop>
            <LoaderUI message="Chargement..." />
        </Backdrop>
    );
};

export default Loader;
