import React from "react";

const Loader = ({ chidren }) => {
    return (
        <div className="loader-wrapper">
            <div className="loader" />
            <div className="text-center">{chidren && <p className="loader-text">{chidren}</p>}</div>
        </div>
    );
};

export default Loader;
